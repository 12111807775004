<template>
  <div style="width:100%;overflow-x:hidden;">
    <img style="width:100%" :src="banner" />
  </div>
</template>

<script>
import banner from '@/assets/xcx-zyb.png'
export default {
  data(){
    return {
      banner
    }
  }
}
</script>

<style scoped>

</style>
